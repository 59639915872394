/**
 * 时间戳转换为时间字符串
 * @param Timestamp 13位时间戳
 * @param format
 */
export const stringForTimestamp = (timestamp, format) => {

    let date = dateForTimestamp(timestamp);

    return stringForDate(date,format);
}

export const stringForDate = (date, format = 'yyyy-MM-dd') => {

    //转2位
    let paddNum = function (num) {
        num += "";
        return num.replace(/^(\d)$/, "0$1");
    };

    let cfg = {
        yyyy: date.getFullYear(),
        MM: paddNum(date.getMonth() + 1),
        dd: paddNum(date.getDate()),
        hh: paddNum(date.getHours()),
        mm: paddNum(date.getMinutes()),
        ss: date.getSeconds(),
    };

    return format.replace(/([a-z])(\1)*/ig,function(m){return cfg[m];});
}

/**
 * 时间戳转为date
 * @param timestamp 13位时间戳
 */
export const dateForTimestamp = (timestamp) => {
    return new Date(parseInt(timestamp));
}

/**
 * 时间字符串转为时间戳
 * @param dateStr
 * @param format
 */
export const timestampForString = (dateStr)=>{
    dateStr = dateStr.replace(/-/g,'/');
    let time = Date.parse(dateStr);
    return time;
}

/*
* 日期格式化
* yyyy年MM月dd日
*/
export function formatTime(param,format) {
    var date = new Date(param);
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    m = m < 10 ? ("0" + m) : m;
    d = d < 10 ? ("0" + d) : d;
    if(format == "yyyy年MM月dd日"){
        return y + "年" + m + "月" + d + "日";
    }else if(format == "yyyy-MM-dd"){
        return y + "-" + m + "-" + d;
    }else if(format == "yyyy-MM-dd HH:mm:ss"){
        let h = date.getHours();
        let M = date.getMinutes();
        let s = date.getSeconds();
        return y + "-" + m + "-" + d + " " + h + ":" + M + ":" + s;
    }
}

function doHandleMonth(month) {
    var m = month;
    if (month.toString().length == 1) {
        m = "0" + month;
    }
    return m;
}

/**
 * 获取day天后的日期
 * date (yyyy-MM-dd)
 * day Number
 */
export function getDay(date,day) {
    var dateStr = new Date(date);
    var targetday_milliseconds = dateStr.getTime() + 1000 * 60 * 60 * 24 * day;
    dateStr.setTime(targetday_milliseconds); //注意，这行是关键代码
    var tYear = dateStr.getFullYear();
    var tMonth = dateStr.getMonth();
    var tDate = dateStr.getDate();
    tMonth = doHandleMonth(tMonth + 1);
    tDate = doHandleMonth(tDate);
    return tYear + "年" + tMonth + "月" + tDate + "日";
}

/**
 * 计算两个日期之间的天数
 * @param dateString1  开始日期 yyyy-MM-dd
 * @param dateString2  结束日期 yyyy-MM-dd
 * @returns {number} 如果日期相同 返回一天 开始日期大于结束日期，返回0
 */
export function getDaysBetween(dateString1,dateString2){
    var startDate = Date.parse(dateString1);
    var endDate = Date.parse(dateString2);
    var days = Math.ceil((endDate - startDate) / (24*60*60*1000));
    let hourTime = (endDate - startDate) % (24*60*60*1000);
    let hours = Math.ceil(hourTime / (60*60*1000));
    let minTime = hourTime % (60*60*1000);
    let mins = Math.ceil(minTime / (60*1000));
    let secondTime = minTime % (60*1000);
    let seconds = Math.ceil(secondTime / (1000));

    return  [days, hours, mins, seconds];
}

/**
 * 配合getDaysBetween使用
 * @param timeArr
 * @returns {number}
 */
 // [天、小时、分、秒]
export function getTimeStr(timeArr) {
    let str = 0;
    if (Math.abs(timeArr[0]) > 0) {
        str = Math.abs(timeArr[0]) + '天'
    } else if (Math.abs(timeArr[1]) > 0) {
        str = Math.abs(timeArr[1]) + '小时'
    } else if (Math.abs(timeArr[2]) > 0) {
        str = Math.abs(timeArr[2]) + '分钟'
    } else if (Math.abs(timeArr[3]) > 0) {
        str = Math.abs(timeArr[3]) + '秒'
    }
    return str;
}
